// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/arrow-top.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*[data-v-41664a52],[data-v-41664a52]:after,[data-v-41664a52]:before{box-sizing:border-box}body[data-v-41664a52]{margin:0;padding:0;overflow-x:hidden}.scroll[data-v-41664a52]{position:fixed;right:18px;bottom:18px;width:60px;height:60px;background-color:#3f3f3f;border-radius:50%;border:none;z-index:1200;cursor:pointer;transition:background-color .3s}.scroll[data-v-41664a52]:before{position:absolute;content:\"\";width:20px;height:24px;top:calc(50% - 12px);left:calc(50% - 10px);background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:contain;transition:top .3s}@media(min-width:1200px){.scroll[data-v-41664a52]:hover{background-color:#0298fa}.scroll[data-v-41664a52]:hover:before{top:calc(50% - 16px)}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
