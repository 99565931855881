
    export default {
        name: 'ScrollToTopButton',
        methods: {
            scrollToTop() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            },
        },
    };
