
    import lottieWeb from 'lottie-web/build/player/lottie_light';
    export default {
        props: {
            data: {
                type: Object,
                required: true,
            },
            loop: Boolean,
            autoplay: {
                type: Boolean,
                default: true,
            },
            settings: {
                type: Object,
                required: false,
                default: () => {},
            },
            height: {
                type: Number,
                required: true,
            },
            width: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                animation: null,
            };
        },
        computed: {
            style() {
                const style = {};
                if (this.width) {
                    style.width = `${this.width}px`;
                }
                if (this.height) {
                    style.height = `${this.height}px`;
                }
                return style;
            },
        },
        mounted() {
            if (lottieWeb) {
                this.animation = lottieWeb.loadAnimation({
                    container: this.$refs.lavContainer,
                    renderer: 'svg',
                    loop: this.loop,
                    autoplay: this.autoplay,
                    animationData: this.data,
                    rendererSettings: this.settings,
                });
            }
        },
        beforeDestroy() {
            this.stop();
            this.destroy();
        },
        methods: {
            play() {
                if (this.animation) {
                    this.animation.play();
                }
            },
            pause() {
                if (this.animation) {
                    this.animation.pause();
                }
            },
            stop() {
                if (this.animation) {
                    this.animation.stop();
                }
            },
            setSpeed(val) {
                if (this.animation) {
                    this.animation.setSpeed(val);
                }
            },
            destroy() {
                if (this.animation) {
                    this.animation.destroy();
                }
            },
        },
    };
