
    import HamburgerBar from './HamburgerBar.vue';

    export default {
        name: 'StickyNavigation',
        components: {
            HamburgerBar,
        },
        data() {
            return {
                isSticky: false,
            };
        },
        computed: {
            menuInfo() {
                return this.$store.state.menuData;
            },
        },
        mounted() {
            window.document.onscroll = () => {
                this.isSticky = window.scrollY > 50;
            };
        },
        methods: {
            scrollToContactForm() {
                document.getElementById('contact-information').scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'nearest',
                    },
                );
            },
        },
    };
