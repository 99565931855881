
    import redCheckmarkAnimation from '../../assets/json/red-checkmark.json';
    import CustomLottiePlayer from './CustomLottiePlayer.vue';

    export default {
        name: 'WorkInvitationBlock',
        components: {
            CustomLottiePlayer,
        },
        data() {
            return {
                redCheckmarkAnimation,
                formData: {
                    name: '',
                    type: '',
                    budget: '',
                    email: '',
                    message: '',
                    phone: '',
                    page: this.$route.path,
                },
                messageSent: false,
                notificationMessage: '',
                isLoading: false,
            };
        },
        watch: {
            notificationMessage() {
                const self = this;
                setTimeout(() => {
                    self.notificationMessage = '';
                }, 4000);
            },
            $route() {
                this.formData.name = '';
                this.formData.type = '';
                this.formData.email = '';
                this.formData.message = '';
                this.formData.budget = '';
                this.formData.phone = '';
                this.formData.page = this.$route.path;
                this.messageSent = false;
            },
        },
        created() {
            try {
                document.getElementById('name').addEventListener('focus', this.reCaptchaInit, false);
                document.getElementById('phone').addEventListener('focus', this.reCaptchaInit, false);
                document.getElementById('email').addEventListener('focus', this.reCaptchaInit, false);
                document.getElementById('message').removeEventListener('focus', this.reCaptchaInit, false)
                document.getElementById('type').removeEventListener('focus', this.reCaptchaInit, false)
            } catch (e) {
                this.$recaptcha.destroy();
            }
        },
        beforeDestroy() {
            document.getElementById('name').removeEventListener('focus', this.reCaptchaDestroy)
            document.getElementById('phone').removeEventListener('focus', this.reCaptchaDestroy)
            document.getElementById('email').removeEventListener('focus', this.reCaptchaDestroy)
            document.getElementById('message').removeEventListener('focus', this.reCaptchaDestroy)
            document.getElementById('type').removeEventListener('focus', this.reCaptchaDestroy)
        },
        methods: {
            reCaptchaInit() {
                this.$recaptcha.init();
            },

            reCaptchaDestroy() {
                this.$recaptcha.destroy();
            },

            isNumber(evt) {
                evt = (evt) || window.event;
                const charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 43 && charCode !== 40 && charCode !== 41) {
                    evt.preventDefault();
                }

                return true;
            },
            async submitForm() {
                try {
                    this.isLoading = true;
                    const response = await this.verifyCaptcha();
                    if (response.data.success) {
                        await this.sendMessage();
                        this.$recaptcha.reset();
                        this.isLoading = false;
                    } else {
                        this.$recaptcha.reset();
                        this.notificationMessage = 'ReCaptcha verification error. Please try again.';
                        this.isLoading = false;
                    }
                } catch (error) {
                    this.isLoading = false;
                }
            },
            async verifyCaptcha() {
                const instance = this.$axios.create();

                try {
                    const token = await this.$recaptcha.execute('submit');

                    return await instance
                        .post(`/captcha-api/siteverify?secret=${process.env.RECAPTCHA_V3_SECRET_KEY}&response=${token}`);
                } catch (error) {
                    this.isLoading = false;

                    return error;
                }
            },
            async sendMessage() {
                const formData = new FormData();
                formData.append('visitor_name', this.formData.name);
                formData.append('visitor_email', this.formData.email);
                formData.append('visitor_message', this.formData.message);
                formData.append('visitor_phone', this.formData.phone);
                formData.append('visitor_project_type', this.formData.type);
                formData.append('visitor_budget', this.formData.budget);
                formData.append('visitor_page', this.formData.page);
                formData.append('_wpcf7_unit_tag', 'wpcf7-f472-p1394-o1');

                const instance = this.$axios.create();

                try {
                    instance
                        .post("/contact-form/", formData)
                        .then(() => {
                            this.messageSent = true;
                            this.formData.name = '';
                            this.formData.type = '';
                            this.formData.budget = '';
                            this.formData.email = '';
                            this.formData.message = '';
                            this.formData.phone = '';
                        });
                } catch (e) {}
            },
        },
    };
