
    import { mapActions } from 'vuex';

    export default {
        name: 'HamburgerBar',
        data() {
            return {
                menu: false,
            };
        },
        computed: {
            menuIsOpen() {
                return this.$store.state.mobileMenuIsOpen;
            },
        },
        watch: {
            menuIsOpen(oldValue) {
                document.body.style.overflow = oldValue ? 'hidden' : '';
            },
        },
        methods: {
            ...mapActions([
                'setMobileMenuIsOpen',
            ]),

            openMenu() {
                this.menu = !this.menuIsOpen;
                this.setMobileMenuIsOpen(this.menu);
            },
        },
    };
