export const state = () => ({
    mobileMenuIsOpen: false,
    topBannerData: [],
    menuData: null,
    trustedByData: null,
    ourWorkData: null,
    servicesData: null,
    technologyData: null,
    testimonialData: null,
    footerData: null,
    basicData: [],
    aboutUsPageData: {},
    projectsPageData: {},
    singleProjectPageData: {},
    servicesPageData: {},
    singleServicePageData: {},
    contactsPageData: {},
    projectsData: [],
    pageChangeAnimation: true,
});

export const mutations = {
    initLoad() {
        this.dispatch('initLoad');
    },
    setMobileMenuIsOpen(state, value) {
        state.mobileMenuIsOpen = value;
    },
    setTopBannerData(state, data) {
        state.topBannerData = data;
    },
    setMenuData(state, data) {
        state.menuData = data;
    },
    setTrustedByData(state, data) {
        state.trustedByData = data;
    },
    setOurWorkData(state, data) {
        state.ourWorkData = data;
    },
    setServicesData(state, data) {
        state.servicesData = data.reverse();
    },
    setTechnologyData(state, data) {
        state.technologyData = data.reverse();
    },
    setTestimonialsData(state, data) {
        state.testimonialData = data;
    },
    setFooterData(state, data) {
        state.footerData = data;
    },
    setBasicData(state, data) {
        state.basicData = data;
    },
    setAboutUsPageData(state, data) {
        state.aboutUsPageData = data;
    },
    setProjectsPageData(state, data) {
        state.projectsPageData = data;
    },
    setSingleProjectPageData(state, data) {
        state.singleProjectPageData = data;
    },
    setServicesPageData(state, data) {
        state.servicesPageData = data;
    },
    setSingleServicePageData(state, data) {
        state.singleServicePageData = data;
    },
    setContactsPageData(state, data) {
        state.contactsPageData = data;
    },
    setProjectsData(state, data) {
        state.projectsData = data;
    },
    setPageChangeAnimationValueTrue(state) {
        state.pageChangeAnimation = true;
    },
    setPageChangeAnimationValueFalse(state) {
        state.pageChangeAnimation = false;
    },
};

export const actions = {
    async nuxtServerInit ({ commit }) {
        commit('initLoad');
    },
    initLoad({ commit }) {
        this.$getDataFromCache(`/wp-json/menus/v1/menus/main-menu`)
            .then((response) => {
                commit('setMenuData', response.data.items);
            });
        this.$getDataFromCache(`/wp-json/wp/v2/services?lang=lt`)
            .then((response) => {
                commit('setServicesData', response.data);
            });
        this.$getDataFromCache(`/wp-json/wp/v2/projects?lang=lt`)
            .then((response) => {
                commit('setProjectsData', response.data);
            });
        this.$getDataFromCache(`/wp-json/wp/v2/testimonials?lang=lt`)
            .then((response) => {
                commit('setTestimonialsData', response.data);
            });
        this.$getDataFromCache(`/wp-json/menus/v1/menus/footer-menu`)
            .then((response) => {
                commit('setFooterData', response.data.items);
            });
        this.$getDataFromCache(`/wp-json/acf/v3/options/options`)
            .then((response) => {
                commit('setBasicData', response.data.acf);
            });
    },
    setMobileMenuIsOpen({ commit }, value) {
        commit('setMobileMenuIsOpen', value);
    },
    setTopBannerData({ commit }, data) {
        commit('setTopBannerData', data);
    },
    setMenuData({ commit }, data) {
        commit('setMenuData', data);
    },
    setTrustedByData({ commit }, data) {
        commit('setTrustedByData', data);
    },
    setOurWorkData({ commit }, data) {
        commit('setOurWorkData', data);
    },
    setServicesData({ commit }, data) {
        commit('setServicesData', data);
    },
    setTechnologyData({ commit }, data) {
        commit('setTechnologyData', data);
    },
    setTestimonialsData({ commit }, data) {
        commit('setTestimonialsData', data);
    },
    setFooterData({ commit }, data) {
        commit('setFooterData', data);
    },
    setBasicData({ commit }, data) {
        commit('setBasicData', data);
    },
    setAboutUsPageData({ commit }, data) {
        commit('setAboutUsPageData', data);
    },
    setProjectsPageData({ commit }, data) {
        commit('setProjectsPageData', data);
    },
    setSingleProjectPageData({ commit }, data) {
        commit('setSingleProjectPageData', data);
    },
    setServicesPageData({ commit }, data) {
        commit('setServicesPageData', data);
    },
    setSingleServicePageData({ commit }, data) {
        commit('setSingleServicePageData', data);
    },
    setContactsPageData({ commit }, data) {
        commit('setContactsPageData', data);
    },
    setProjectsData({ commit }, data) {
        commit('setProjectsData', data);
    },
    setPageChangeAnimationValueTrue({ commit }) {
        commit('setPageChangeAnimationValueTrue');
    },
    setPageChangeAnimationValueFalse({ commit }) {
        commit('setPageChangeAnimationValueFalse');
    },
};
