
    export default {
        name: 'Footer',
        computed: {
            footerData() {
                return this.$store.state.footerData;
            },

            footerCopyright() {
                return this.$store.state.basicData;
            },
        },
        methods: {
            redirectToPage(title, slug) {
                return title.toLowerCase() === 'scoding'
                    ? `/${slug}`
                    : `/services`;
            },
        },
    };
