
    import StickyNavigation from './StickyNavigation.vue';
    import MobileMenuSection from './MobileMenuSection.vue';

    export default {
        name: 'HeaderSection',
        components: {
            MobileMenuSection,
            StickyNavigation,
        },
        data() {
            return {
                active: false,
                cookieEnabled: false,
                prevHeight: 0,
            };
        },
        computed: {
            mobileMenuIsOpen() {
                return this.$store.state.mobileMenuIsOpen;
            },
        },
    };
