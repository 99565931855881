
    import CustomLottiePlayer from './CustomLottiePlayer.vue';
    import cloudAnimation from '../../assets/json/cloud.json';

    export default {
        name: 'WorkInvitationBlock',
        components: {
            CustomLottiePlayer,
        },
        data() {
            return {
                cloudAnimation,
            };
        },
        computed: {
            contactSectionData() {
                return this.$store.state.basicData;
            },
        },
    };
