export default function (req, res, next) {
    const redirects = [
        {
            from: '/konsultavimas',
            to: '/paslaugos/konsultavimas',
        },
        {
            from: '/apie',
            to: '/apie-mus',
        },
        {
            from: '/reprezentacines-svetaines',
            to: '/paslaugos/reprezentacines-svetaines',
        },
        {
            from: '/unikalios-sistemos',
            to: '/paslaugos/unikalios-sistemos',
        },
        {
            from: '/mobiliosios-aplikacijos',
            to: '/paslaugos/mobiliosios-aplikacijos',
        },
        {
            from: '/dizaino-sprendimai',
            to: '/paslaugos/dizaino-sprendimai',
        },
        {
            from: '/internetines-parduotuves',
            to: '/paslaugos/internetines-parduotuves',
        },
        {
            from: '/slapuku-politika',
            to: '/privatumo-politika',
        },
        {
            from: '/atlikti-darbai/link2job',
            to: '/projektai/link2job',
        },
        {
            from: '/atlikti-darbai/lenex',
            to: '/projektai/lenex',
        },
        {
            from: '/atlikti-darbai/auto-aves',
            to: '/projektai/auto-aves',
        },
        {
            from: '/atlikti-darbai/dih-santaka',
            to: '/projektai/dih-santaka',
        },
        {
            from: '/atlikti-darbai/kovenda',
            to: '/projektai/kovenda',
        },
        {
            from: '/atlikti-darbai/migdolas',
            to: '/projektai/migdolas',
        },
        {
            from: '/atlikti-darbai/maksimalietis/',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/pomdoors',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/dabdental',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/plyhouse',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/luxart',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/dressupgames',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/serfas',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/monada',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/unboxed',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/php-mid',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/pingler',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/vurbis',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/anvista',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/aecparcel',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/autobroliai',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/elvprojektai',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/bedcolours',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/php',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/frontend',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/investmore',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/shipzee',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/musio-frontas',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/therapeutic-hands',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/protinga-siena',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/nemuno-takas',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/startup-highway',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/foto-kursai',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/lietuvos-pastas',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/porsche-cayenne',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/vaiko-ateitis',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/kasimo-paslaugos',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/atlantic-express',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/paal-kahrs-arkitekter',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai/amerika-lietuvoje-2',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai-kategorija/kiti-grafiniai-darbai',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai-kategorija/svetainiu-dizainas/page/3',
            to: '/projektai',
        },
        {
            from: '/atlikti-darbai-kategorija/svetainiu-dizainas/page/2',
            to: '/projektai',
        },
        {
            from: '/karjera/ui-ux-dizaineris-e',
            to: '/kontaktai',
        },
    ];
    // TODO: reikia visu galimu senos sveitaines puslapiu.
    const redirect = redirects.find((r) => r.from === req.url);
    if (redirect) {
        console.log(redirect.to)
        next(redirect.to)
        // res.writeHead(301, { Location: redirect.to });
        // res.end();
    } else {
        next();
    }
}
// console.log(redirects.some((r) => r.from.includes(req.url)))
// console.warn(r.from.substring(0,5))
// console.log(req.url.substring(0,5))
// const redirect = redirects.find((r) => r.from === req.url);
// const redirect1 = redirects.find((r) => r.from.substring(0,5) === req.url.substring(0,5));
// console.log(req.url)
// console.log(redirect)
// console.log(redirect1)
