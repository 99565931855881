
    import { mapActions } from 'vuex';

    export default {
        name: 'MobileMenuSection',
        computed: {
            menuInfo() {
                return this.$store.state.menuData;
            },
        },
        methods: {
            ...mapActions([
                'setMobileMenuIsOpen',
            ]),
            closeMenu() {
                this.setMobileMenuIsOpen(false);
            },
            scrollToContactForm() {
                this.closeMenu();
                document.getElementById('contact-information').scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'nearest',
                    },
                );
            },
        },
    };
