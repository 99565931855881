
    import SocialNetworksTab from '../components/MainComponent/SocialNetworksTab.vue';
    import ContactSection from '../components/MainComponent/ContactSection.vue';
    import PrivacyPolicy from '../components/MainComponent/PrivacyPolicy.vue';
    import ScrollToTopButton from '../components/MainComponent/ScrollToTopButton.vue';
    import Footer from '../components/MainComponent/Footer.vue';
    import HeaderSection from '../components/MainComponent/HeaderSection.vue';
    import PageChangeAnimation from '../components/MainComponent/PageChangeAnimation.vue';

    export default {
        head: function () {
            const currentPath = this.$router.currentRoute.path;

            return {
                meta: [
                    { hid: 'og:url', property: 'og:url', content: process.env.APP_URL + currentPath },
                    { hid: 'twitter:url', name: 'twitter:url', content: process.env.APP_URL + currentPath }
                ],
            };
        },
        components: {
            HeaderSection,
            Footer,
            ScrollToTopButton,
            PrivacyPolicy,
            ContactSection,
            SocialNetworksTab,
            PageChangeAnimation,
        },
        data() {
            return {
                active: false,
                cookiesAccepted: false,
                prevHeight: 0,
                isLoading: false
            };
        },
        computed: {
            mobileMenuIsOpen() {
                return this.$store.state.mobileMenuIsOpen;
            },
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
            this.checkIfCookiesAccepted();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            acceptCookies() {
                this.$cookies.set('cookies_accepted', true, '1d');
                this.cookiesAccepted = true;
            },
            checkIfCookiesAccepted() {
                const cookieExists = this.$cookies.isKey('cookies_accepted');

                if (cookieExists) {
                    this.cookiesAccepted = true;
                }
            },
            handleScroll() {
                this.active = window.scrollY > 0;
            },
        },
    };
