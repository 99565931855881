
    import ContactForm from './ContactForm.vue';
    import WorkInvitationBlock from './WorkInvitationBlock.vue';

    export default {
        name: 'ContactSection',
        components: {
            WorkInvitationBlock,
            ContactForm,
        },
    };
